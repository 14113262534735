<template>
    <video class="background_video" src="https://static.vecteezy.com/system/resources/previews/002/021/615/mp4/blockchain-network-concept-free-video.mp4" loop autoplay muted playsinline></video>
    <div class="wrapper">
        <header class="header">
            <a href="/" class="main_logo">
                <h1 class="title">Blockit</h1>
            </a>
        </header>
        <section class="intro_section">
            <div class="big_text_area">
                <span class="text">
                    <span class="first">BLOCKCHAIN RESEARCH CLUB</span><br />
                    <span class="second">BLOCKCHAIN & DEV & SECURITY</span>
                </span>
            </div>
        </section>
        <section class="link_section">
            <nav class="navigation">
                <ul class="link_list">
                    <RouterLink :to="{ path: 'intro' }" class="link_item">
                        <li class="link">INTRO</li>
                    </RouterLink>
                    <RouterLink :to="{ path: 'activity' }" class="link_item">
                        <li class="link">ACTIVITY</li>
                    </RouterLink>
                    <a class="link_item" href="https://forms.gle/FYbQncxsjEtJr9PE6" target="_blank">
                        <!--클라우드 플레어 환경변수로 바꾸고 싶은데 어려울까봐 그냥 정적으로 박아둠. 바꿀 때마다 커밋해야함.-->
                        <li class="link">RECRUIT</li>
                    </a>
                </ul>
            </nav>
        </section>
        <footer class="footer">
            <div class="description_wrapper">
                <p class="description">L: SEOUL, REPUBLIC OF KOREA</p>
                <div class="description_under">
                    <p class="description">부장: 김건희, 010.2094.4970</p>
                    <p class="description">쀼장: 권명경, 010.9197.3721</p>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {

}
</script>

<style>
</style>