import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '../views/MainPage';
import IntroducePage from '../views/IntroducePage';
import ActivityPage from '../views/ActivityPage';

const routes = [
  {
    path: '/',
    name: 'MainPage',
    component: MainPage
  },
  {
    path: '/intro',
    name: 'Introduce',
    component: IntroducePage
  },
  {
    path: '/activity',
    name: 'Activity',
    component: ActivityPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
