<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <header class="default_header">
    <RouterLink :to="{ path: '/' }" class="logo">
      <img src="@/assets/image/logo.svg" class="logo_img" />
    </RouterLink>
    <RouterLink :to="{ path: 'intro' }" class="link_item">
      <p class="link">INTRO</p>
    </RouterLink>
    <RouterLink :to="{ path: 'activity' }" class="link_item">
      <p class="link">ACTIVITY</p>
    </RouterLink>
  </header>
</template>
