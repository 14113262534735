<template>
  <Header />
  <img src="@/assets/image/back1.jpg" class="default_img" />
  <div class="default">
    <h1>소개</h1>
    <div class="panel">
      <h2>Who are we?</h2>
      <p>차세대 기술로 각광받는 블록체인은 금융, DNS, 웹 3.0 등 중앙화된 시스템에서 탈중앙화를 목표로 설계되었습니다.
        <br />저희 동아리는 이러한 블록체인에 대한 이해와 블록체인을 활용할 방안에 대해 연구함으로써 차세대 기술에 기여하고 나아가 블록체인 기술의 선구자가 되는 것을 목표로 합니다.
      </p>
    </div>
    <div class="panel">
      <h2>Feature</h2>
      <div class="feature">
        <div class="feature_item" ref="feature_item1">
          <img src="@/assets/image/tech.png" />
          <h4>기술</h4>
        </div>
        <div class="feature_item" ref="feature_item2">
          <img src="@/assets/image/pbl.png" />
          <h4>PBL</h4>
        </div>
        <div class="feature_item" ref="feature_item3">
          <img src="@/assets/image/communication.png" />
          <h4>소통</h4>
        </div>
      </div>
      <h4 class="feature_title">기술</h4>
      <p class="feature_desc" id="feature_desc1">4차 산업혁명 핵심기술 중 하나인 블록체인을 다룸으로써 자소서 쓰기에 유리합니다.</p>
      <h4 class="feature_title">PBL</h4>
      <p class="feature_desc" id="feature_desc2">학습자에게 문제를 제시하고 해결방안을 떠올리게 하는 문제중심학습을 지향합니다.</p>
      <h4 class="feature_title">소통</h4>
      <p class="feature_desc" id="feature_desc3">선후배, 동기간의 수평적인 의견 소통을 통해 올바른 커뮤니케이션을 이룹니다.</p>
    </div>
    <div class="panel">
      <h2>Curriculum</h2>
      <h4>블록체인의 이해</h4>
      <p>비트코인을 중심으로 블록체인의 구성 요소와 기술의 기반을 다집니다.</p>
      <h4>Solidity</h4>
      <p>블록체인 플랫폼 구현의 교과서 격이 되는 Solidity 언어를 배웁니다.</p>
      <h4>DApp</h4>
      <p>Truffle 프레임워크를 이용하여 스마트 계약을 실행하는 어플리케이션인 DApp을 구현합니다.</p>
      <h4>JavaScript</h4>
      <p>DApp 구현에 사용하게 될 언어인 JavaScript를 배웁니다.</p>
      <h4>프로젝트</h4>
      <p>배운 블록체인 기술들을 적용해 각자만의 프로젝트를 진행합니다.</p>
    </div>
  </div>
</template>

<script setup>
import Header from "@/components/Header.vue";
import { ref, onMounted } from "vue";
const feature_item1 = ref();
const feature_item2 = ref();
const feature_item3 = ref();

const addEvent = (variable, i) => {
  variable.value.addEventListener("mouseover", () => {
    const feature_desc = document.getElementById("feature_desc" + i);
    feature_desc.style.visibility = "visible";
    feature_desc.style.width = "100%";
    feature_desc.style.height = "100%";
  });
  variable.value.addEventListener("mouseout", () => {
    const feature_desc = document.getElementById("feature_desc" + i);
    feature_desc.style.visibility = "hidden";
    feature_desc.style.width = "0";
    feature_desc.style.height = "0";
  });
}

onMounted(() => {
  for (let i = 1; i <= 3; i++) {
    addEvent(eval("feature_item" + i), i);
  }
});
</script>

<style>
@import "~@/assets/style/default/styles.css";
</style>